import React, { useState, useEffect } from "react";
import API from '../../Api/Api';
import { IMAGE_PATHS } from '../../Functions/Constant';


const SetupSlider = () => {
     const IMAGE_PATHS2 = {
        1: IMAGE_PATHS.PROFILE,
        2: IMAGE_PATHS.EXPERIENCE,
        3: IMAGE_PATHS.EDUCATION,
        4: IMAGE_PATHS.PHOTO,
    
    };
    const allSlides = [
        {
            key: "profile_updated",
            title: "Set Up Your Account",
            description: "Complete your profile to receive ratings and reviews.",
            buttonText: "Update profile",
            redirectTo: "/employee_detail",
        },
        {
            key: "experience_updated",
            title: "Update Your Experience",
            description: "Get ratings from colleagues and employers.",
            buttonText: "Update Experience",
            redirectTo: "/employee_detail?section=reviews",
        },
        {
            key: "education_updated",
            title: "Update Your Education",
            description: "Get ratings from friends and teachers.",
            buttonText: "Update Education",
            redirectTo: "/employee_detail?section=academic",
        },
        {
            key: "photo", // Not based on API; always show
            title: "Update Your Photo",
            description: "Get recognized by your connections for ratings and reviews.",
            buttonText: "Update photo",
            redirectTo: "/profile",
        },
    ];

    const [filteredSlides, setFilteredSlides] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isOpenWebsitePopup, setIsOpenWebsitePopup] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % filteredSlides.length);
    };

    const handleButtonClick = () => {
        window.location.href = filteredSlides[currentSlide].redirectTo;
    };

    const fetchDataUserUpdate = async () => {
        try {
            const endpoint = `/user/check_userdetial`;
            const res = await API.get(endpoint);

            if (res.result) {
                const {
                    profile_updated = 0,
                    experience_updated = 0,
                    education_updated = 0,
                    language_updated = 0,
                } = res.result;

                const filtered = allSlides.filter(slide => {
                    if (slide.key === "photo") return profile_updated === 0;
                    if (slide.key === "experience_updated") return experience_updated === 0;
                    if (slide.key === "education_updated") return education_updated === 0;
                    if (slide.key === "profile_updated") return education_updated === 0 && experience_updated === 0 && language_updated === 0;
                    return true; // for photo
                });

                setFilteredSlides(filtered);
                if(profile_updated == 0 || experience_updated == 0 || education_updated == 0){
                    setIsOpenWebsitePopup(true);
                }
                //console.log("filtered",filtered);
                if(filtered.length > 0){
                    const sliderKey = filtered[0].key;
                    if(sliderKey === "profile_updated") {
                        setImageIndex(0);
                    }
                    if(sliderKey === "experience_updated") {
                        setImageIndex(1);
                    }
                    if(sliderKey === "education_updated") {
                        setImageIndex(2);
                    }
                    if(sliderKey === "photo") {
                        setImageIndex(3);
                    }  
                }
                //setCurrentSlide(filteredSlides.length-4);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataUserUpdate();
    }, []);

    if (filteredSlides.length === 0) return null; // No steps to show

    const current = filteredSlides[currentSlide];

    return (
        <>
        {isOpenWebsitePopup?( <div className="flex mb-3 bg-white p-4 border border-gray-300 rounded-lg relative shadow-md">
            <div className="w-full">
                {/* Header */}
                <div className="flex justify-between items-center mb-2">
                    <h2 className="text-[20px] text-black font-semibold !mb-[0px]">
                        {current.title}
                    </h2>
                    <span className="text-[20px] text-black">
                        {`${currentSlide + 1}/${filteredSlides.length} complete`}
                    </span>
                    <button onClick={()=>{ setIsOpenWebsitePopup(false) }} style={{position:"absolute",top:'8px',right:'8px'}} type="button" class="btn-close" aria-label="Close"></button>
                </div>

                {/* Image Placeholder */}
                <div className="w-full  bg-gray-100 rounded-md mb-3 flex items-center justify-center">
                    <img
                        src={IMAGE_PATHS2[imageIndex+1] }
                        alt="Slide Visual"
                        className="max-h-full max-w-full object-contain"
                    />
                </div>

                {/* Description */}
                <p className="text-[15px] text-gray-700 mb-3">
                    {current.description}
                </p>

                {/* Button */}
                <button
                    onClick={handleButtonClick}
                    className="btn-explore-white"
                >
                    {current.buttonText}
                </button>
            </div>

            {/* Arrow Button */}
            {/* {filteredSlides.length > 1 && (
                <button
                    onClick={nextSlide}
                    className="absolute top-1/2 -translate-y-1/2 right-2 w-8 h-8 flex items-center justify-center bg-white border border-gray-300 rounded-full bks"
                >
                    <i className="fa fa-chevron-right text-gray-700 text-xs"></i>
                </button>
            )} */}
        </div>):(<></>)}
       
        </>
        
    );
};

export default SetupSlider;
