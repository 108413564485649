import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup"; // ✅ Yup for form validation
import MainHeader from "../../Pages/Header/MainHeader";
import FooterStyle from '../../Pages/Footer/Footer';
import API from '../../Api/Api';
import { IMAGE_PATHS, DOMAIN_URL } from '../../Functions/Constant';
import ProfilePhotoPopup from '../../Components/ProfilePhotoPopup'
import ConfirmationPopup from '../../Components/ConfirmationPopup'
const ProfilePage = () => {
    const [profilePic, setProfilePic] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [imageError, setImageError] = useState("");
    const [locationquery, setLocationquery] = useState("");
    const [locationsuggestions, setLocationSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [formValues, setFormValues] = useState({
        name: "",
        phone: "",
        email: "",
        city: "",
        gender: "0"
    });
    const [popupData, setPopupData] = useState({
        isOpen: false,
        title: "",
        message: "",
        onClose: () => { },
        onConfirm: () => { }
    });
    // ✅ Validation Schema using Yup
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
            .required("Name is required"),
        phone: Yup.string()
            .matches(/^[0-9]{1,12}$/, "Only numbers allowed (Max 12 digits)")
            .required("Phone number is required"),
        // email: Yup.string().email("Invalid email format").required("Email is required"),
        city: Yup.string().required("Location is required"),
        gender: Yup.string().required("Gender is required")
    });

    // ✅ Fetch user data
    const fetchDataUser = async () => {
        try {
            const res = await API.get(`/user`);
            if (res.user) {
                setFormValues({ name: res.user.name, phone: res.user.phone, email: res.user.email, city: res.user.city_id, gender: res.user.gender || "" });
                setProfilePic(DOMAIN_URL + '/' + res.user.profile_image);
                if (res.user.city_name != null) {
                    setLocationquery(res.user.city_name + ', ' + res.user.state_name + ', ' + res.user.country_name);
                }

                //setFieldValue("city", res.user.city_name);
                // setValues({
                //     name: res.user.name || "",
                //     phone: res.user.phone || "",
                //     // email: res.user.email || "",
                //     city: res.user.city || "",
                // });
                //  setLocationquery(res.user.city || "");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };
    useEffect(() => {
        fetchDataUser();
    }, []);
    // ✅ Fetch city suggestions from API
    const fetchLocation = async (search) => {
        if (!search) {
            setLocationSuggestions([]);
            return;
        }
        setLoading(true);
        try {
            const response = await API.get(`/autocomplete/locations?query=${search}`);
            const locationList = Array.isArray(response) ? response : [];
            setLocationSuggestions(locationList);
        } catch (error) {
            console.error("Error fetching locations:", error);
            setLocationSuggestions([]);
        } finally {
            setLoading(false);
        }
    };

    // ✅ Handle Profile Picture Upload
    const handleProfilePicChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileType = file.type;
            const fileSize = file.size;

            if (!["image/png", "image/jpeg", "image/jpg"].includes(fileType)) {
                setImageError("Only PNG, JPG, and JPEG formats are allowed.");
                return;
            }
            if (fileSize > 1024 * 1024) {
                setImageError("File size must be less than 1MB.");
                return;
            }
            setFile(file);

        }
    };
    const dataURLtoFile = (dataUrl, filename) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
    const uploadChangePhoto = async (fileUrl) => {
        const file = dataURLtoFile(fileUrl, 'edited-photo.png');
        setFile(file);
        uploadProfilePic(file);
    };
    const uploadProfilePic = async (file) => {
        if (!file) {  // Check if file is selected
            setImageError("Please select an image.");
            return;
        } else {
            const formData = new FormData();
            formData.append("profile_pic", file);

            const token = localStorage.getItem('jwt_token_jobcritic');

            try {
                const result = await API.post('/user/upload-profile-pic', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`, // Token from login
                    },
                });

                console.log("API Response:", result);

                // Set profile picture after successful upload
                setProfilePic(URL.createObjectURL(file));
                setImageError(""); // Clear error message
                setShowPopup(false);
                localStorage.removeItem('userData');
                window.location.reload();

            } catch (error) {
                console.error("Upload failed:", error);
                setImageError("Failed to upload image. Please try again.");
            }
        }


    };


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        try {
            const payload = { name: values.name, phone: values.phone, city_id: values.city, gender: values.gender };

            console.log("Submitting Payload:", payload);

            const response = await API.put('/user/update-profile', payload);

            console.log("API Response:", response);

            fetchDataUser();
            setPopupData({
                isOpen: true,
                title: "Success!",
                message: "Your details have been updated successfully.",
                onConfirm: () => setPopupData({ isOpen: false }), // Close Popup on Confirm
                onClose: () => setPopupData({ isOpen: false })    // Close Popup on Close
            });

        } catch (error) {
            console.error(" Error submitting form:", error);
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    const CustomSelect = ({ label, name, options, onChange }) => {
        return (
          <div className="col-md-12 pb-2">
            <label className="block text-sm font-semibold text-gray-600">{label} *</label>
            <div className="relative">
              <Field
                as="select"
                name={name}
                className="form-control fomc-cls px-3 py-2 w-full appearance-none bg-gray-200 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={onChange}
              >
                <option value="0" disabled selected>
                  Select Gender
                </option>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
              {/* Custom arrow icon */}
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
            <ErrorMessage name={name} component="p" className="text-red-500 text-xs mt-1" />
          </div>
        );
      };
      
    
      const genderOptions = [
        
        { value: '1', label: 'Male' },
        { value: '2', label: 'Female' },
        { value: '3', label: 'Other' },
      ];
    return (
        <div>
            <MainHeader />
            <section className="pt-[120px] pb-[120px]">
                <Container>
                    <Row>
                        <Col lg={2} md={12} sm={12}>
                            {/* Profile Picture */}
                            <div className="relative">
                            <div
                                className=" w-28 h-28 rounded-full overflow-hidden border-4 border-gray-200 cursor-pointer"
                                onClick={() => setShowPopup(true)}
                            >
                                <img
                                    src={profilePic || IMAGE_PATHS.USER}
                                    alt="Profile"
                                    className="w-full h-full object-cover"
                                />

                            </div>
                            <div className="absolute inset-0  flex items-center justify-center  text-xs font-bold hover:opacity-100 transition top-[67px] text-center">

                                <i class="fa-solid fa-camera cursor-pointer"  onClick={() => setShowPopup(true)}></i>
                            </div>
                            </div>
                        </Col>

                        <Col lg={10} md={12} sm={12}>
                            {/* ✅ Formik Form with Validation */}
                            <Formik
                                enableReinitialize
                                initialValues={formValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, handleChange, setFieldValue, handleSubmit, dirty, isValid }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Col md={12} className="pb-2">
                                            <label className="block text-sm font-semibold text-gray-600">Name</label>
                                            <Field
                                                type="text"
                                                name="name"
                                                placeholder="Enter name..."
                                                className="form-control fomc-cls px-3 py-2"
                                                onChange={handleChange}
                                            />
                                            <ErrorMessage name="name" component="p" className="text-red-500 text-xs mt-1" />
                                        </Col>

                                        <Col md={12} className="pb-2">
                                            <label className="block text-sm font-semibold text-gray-600">Phone Number</label>
                                            <Field
                                                type="number"
                                                name="phone"
                                                placeholder="Enter phone..."
                                                className="form-control fomc-cls px-3 py-2"
                                                onChange={handleChange}
                                            />
                                            <ErrorMessage name="phone" component="p" className="text-red-500 text-xs mt-1" />
                                        </Col>

                                        <Col md={12} className="pb-2">
                                            <label className="block text-sm font-semibold text-gray-600">Email ID</label>
                                            <Field
                                                type="email"
                                                name="email"
                                                className="form-control fomc-cls px-3 py-2"
                                                placeholder="Enter email..."
                                                disabled
                                            />
                                            {/* <ErrorMessage name="email" component="p" className="text-red-500 text-xs mt-1" /> */}
                                        </Col>
                                        <Col md={12} className="pb-2">
                                            <CustomSelect
                                                label="Gender"
                                                name="gender"
                                                options={genderOptions}
                                                onChange={handleChange}
                                            />
                                            {/* <label className="block text-sm font-semibold text-gray-600">Gender *</label>
                                            <Field
                                                as="select"
                                                name="gender"
                                                className="form-control fomc-cls px-3 py-2"
                                                onChange={handleChange}
                                            >
                                                <option value="0">Select Gender</option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                                <option value="3">Other</option>
                                                
                                            </Field>
                                            <ErrorMessage name="gender" component="p" className="text-red-500 text-xs mt-1" /> */}
                                        </Col>

                                        {/* City Input */}
                                        <Col md={12} className="relative ">
                                            <label className="block text-sm font-semibold text-gray-600">Current City *</label>

                                            <div className="relative">
                                                {/* Search Icon inside Input */}
                                                {/* <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer">
                                                    <i className="fas fa-search"></i>
                                                </span> */}

                                                {/* Location Input */}
                                                <Field
                                                    type="text"
                                                    name="city"
                                                    className="form-control fomc-cls  py-2 w-full" // Adjust padding for icon space
                                                    placeholder="Search location..."
                                                    value={locationquery}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        setLocationquery(value);
                                                        //setFieldValue("city", value);
                                                        if (value.length > 1) fetchLocation(value);
                                                        else setLocationSuggestions([]);
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </div>

                                            <ErrorMessage name="city" component="p" className="text-red-500 text-xs mt-1" />

                                            {/* City Suggestions */}
                                            {locationsuggestions.length > 0 && (
                                                <ul className="list-group position-absolute w-full mt-1 bg-white shadow-lg rounded z-50">
                                                    {locationsuggestions.map((location) => (
                                                        <li
                                                            key={location.city_id}
                                                            className="list-group-item list-group-item-action cursor-pointer px-3 py-2 hover:bg-gray-100"
                                                            onClick={() => {
                                                                const cityName = `${location.city_name}, ${location.state_name}, ${location.country_name}`;
                                                                setLocationquery(cityName);
                                                                setFieldValue("city", location.city_id);
                                                                setLocationSuggestions([]);
                                                            }}
                                                        >
                                                            {location.city_name}, {location.state_name}, {location.country_name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Col>


                                        {/* Buttons */}
                                        <div className="mt-6 flex justify-end gap-4">
                                            <Button
                                                className={` ${!isValid || !dirty
                                                    ? "btn-explore-yellow-disabled !border-gray-300 !border-[1px] disabled:!cursor-not-allowed disabled:!bg-gray-300 disabled:opacity-50 disabled:hover:!border-gray-300 disabled:hover:!bg-gray-300 disabled:!text-white disabled:hover:!text-white"
                                                    : "btn-explore-yellow"
                                                    }`}
                                                type="submit"
                                                disabled={!isValid || !dirty}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </Container>
                {showPopup && (
                    <ProfilePhotoPopup uploadChangePhoto={uploadChangePhoto} imageSrcVal={profilePic || IMAGE_PATHS.USER} onClose={() => setShowPopup(false)} />
                )}
            </section>
            {popupData.isOpen && (
                <ConfirmationPopup
                    isOpen={popupData.isOpen}
                    title={popupData.title}
                    message={popupData.message}
                    onClose={() => setPopupData({ isOpen: false })}
                    onConfirm={() => setPopupData({ isOpen: false })}
                    showCancelButton={false}
                />
            )}
            <FooterStyle theme="light" className="bg-black text-[#828282]" />
        </div>
    );
};

export default ProfilePage;
