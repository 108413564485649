import React from "react";
import { Modal, Button } from "react-bootstrap";

const ShareLinkModal = ({ show, onClose, onCreateLink,link }) => {
    //const link = "https://chatgpt.com/share/…";

    const handleCopy = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(link)
                .then(() => {
                    onCreateLink?.();
                })
                .catch((err) => {
                    console.error("Clipboard copy failed:", err);
                    fallbackCopy(link);
                });
        } else {
            fallbackCopy(link);
        }
    };

    const fallbackCopy = (text) => {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.style.position = "fixed"; // prevent scroll jump
        textarea.style.top = "-9999px";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();

        try {
            const successful = document.execCommand("copy");
            if (successful) {
                onCreateLink?.();
            } else {
            }
        } catch (err) {
        }

        document.body.removeChild(textarea);
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered
            backdrop="static"
            keyboard={true}
            dialogClassName="custom-modal-dialog"
        >
            <Modal.Body className="text-white p-4 rounded-4">
                <div className="d-flex justify-content-between align-items-start">
                    <h6 className="fw-semibold text-black">Get More Ratings</h6>
                    <button className="btn-close" onClick={onClose} aria-label="Close" />
                </div>

                <p className="text-black mb-3">
                    Share your profile link to receive ratings and enhance your professional credibility.
                </p>

                <div className="d-flex align-items-center border rounded-[30px] px-3 py-2 mb-3" style={{ border: '1px solid #dee2e6' }}>
                    <span className="text-dark flex-grow-1 small">https://jobcritics.com/get-more-rating/…</span>
                    <Button onClick={handleCopy} className="btn-explore-yellow">
                        🔗 Copy link
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ShareLinkModal;
