import React, { useState } from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import './AddPhotoModal.css';

const AddPhotoModal = ({ onClose, onPhotoSelect, imageSrcVal, changeTitle }) => {
  const [useCamera, setUseCamera] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const [photoDataUri, setPhotoDataUri] = useState(null);

  // Handle photo capture
  const handleTakePhoto = (dataUri) => {
    setPhotoDataUri(dataUri); // Store the captured photo
    onPhotoSelect(dataUri);   // Pass the photo to the parent component
    onClose();               // Close the modal after capture
  };

  // Handle camera error
  const handleCameraError = (error) => {
    setCameraError(`Camera error: ${error.message || error}`);
    console.error('Camera error:', error);
  };

  // Toggle between camera and file upload
  const toggleCamera = () => {
    setUseCamera(!useCamera);
    setCameraError(null);
    setPhotoDataUri(null); // Clear previous photo on toggle
    if (useCamera) {
      changeTitle('Add Photo');
      
    }else{
      changeTitle('Click Photo'); // Reset photo data URI when switching to file upload
    }
  };

  // Handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const photoURL = URL.createObjectURL(file);
      onPhotoSelect(photoURL);
      onClose();
    }
  };

  return (
    <div className="modal-content">
      {cameraError && <div className="error-message">{cameraError}</div>}
      {!useCamera ? (
        <div className="upload-option">
          <input
            type="file"
            id="photoUpload"
            accept="image/jpeg, image/png"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
          <div className="flex justify-center mt-[82px] mb-[160px]">
            <div className="w-[150px] h-[150px] rounded-full overflow-hidden">
              <img
                src={imageSrcVal || 'default-image.jpg'} // Fallback image if none provided
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="absolute bottom-0 left-0 w-full bg-white p-4 border-t border-gray-300 flex gap-2 rounded-b-lg justify-end">
            <label htmlFor="photoUpload" className="btn-explore-white">
              Upload Image
            </label>
            <button onClick={toggleCamera} className="btn-explore-yellow">
              Use Camera
            </button>
          </div>
        </div>
      ) : (
        <div className="camera-container">
          {!photoDataUri ? (
            <Camera
              onTakePhoto={(dataUri) => handleTakePhoto(dataUri)}
              onCameraError={(error) => handleCameraError(error)}
              idealFacingMode="environment" // Use rear camera if available
              idealResolution={{ width: 640, height: 480 }} // Set resolution
              isImageMirror={false} // Disable mirroring for natural orientation
              isMaxResolution={true} // Use maximum resolution if ideal fails
              isSilentMode={false} // Enable sound feedback
              isDisplayStartCameraError={true} // Show error if camera fails to start
            />
          ) : (
            <div className="photo-preview">
              <img src={photoDataUri} alt="Captured" className="w-full h-auto max-h-[400px] object-cover rounded-lg" />
              <div className="camera-controls mt-4">
                <button onClick={toggleCamera} className="btn-explore-white">
                  Retake Photo
                </button>
                <button onClick={onClose} className="btn-explore-yellow">
                  Confirm
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddPhotoModal;