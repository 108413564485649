import React from "react";
import { Modal, Button } from "react-bootstrap";
import { IMAGE_PATHS} from '../../Functions/Constant';

const WelcomeModal = ({ show, handleClose }) => {
    const handleSetupProfile = () => {
        document.cookie = "welcome_modal_seen=true; path=/; max-age=604800";

        window.location.href = "/employee_detail"; 
        handleClose();
    };
    return (
        <Modal show={show} onHide={handleClose} centered  dialogClassName="custom-modal-dialog">
            <Modal.Body className="text-center p-5 rounded-lg">
                {/* Welcome Image */}
                <img 
                    src={IMAGE_PATHS.WELCOME} 
                    alt="Welcome" 
                    className="mx-auto mb-4 w-[164px] h-auto" 
                />


                {/* Description */}
                <p className="text-gray-600 text-sm mb-4">
                Start building your professional credibility with reviews from your connections.
                </p>

                {/* Button */}
                <Button 
                    onClick={handleSetupProfile}
                    className="btn-explore-yellow w-full"
                >
                    Set Up Your Profile
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default WelcomeModal;
