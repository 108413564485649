import React, { useRef, forwardRef, useImperativeHandle, useState, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Container, Row, Col } from "react-bootstrap";
import { getStarColorClass, getRatingLabel } from '../../Functions/RatingLabelColor';
import { IMAGE_PATHS, DOMAIN_URL } from '../../Functions/Constant';
import API from '../../Api/Api';
import StarRating from '../../Components/StarRating'

const ProfileReviewPDF = forwardRef((props, ref,) => {
    const { userDataS: userDataS } = props;
    const { ratingsM: ratingsM } = props;
    const pdfRef = useRef();
    const [formValues, setFormValues] = useState({ phone: "", email: "", city: "" });
    const [locationQuery, setLocationQuery] = useState("");
    const [education, setEducation] = useState([]);
    const [language, setLanguage] = useState([]);
    const [experiences, setExperiences] = useState([]);

    const fetchEducation = async () => {
        const response = await API.get(`/education`);
        setEducation(response.education);
        console.log(response);
    };

    const fetchDataUser = async () => {
        try {
            const res = await API.get(`/user`);
            if (res.user) {
                setFormValues({
                    phone: res.user.phone || "",
                    email: res.user.email || "",
                    city: res.user.city_id || ""
                });
                if (res.user.city_name != null) {
                    setLocationQuery(`${res.user.city_name}, ${res.user.state_name}, ${res.user.country_name}`);
                }
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const fetchLanguage = async () => {
        const response = await API.get(`/language`);
        setLanguage(response.languages);
        console.log(response);
    };


    const fetchExperience = async () => {
        const response = await API.get(`/experiences`);
        setExperiences(response.experiences);
        console.log(response);
    };

    const formatExperienceDate = (start_month, start_year, end_month, end_year) => {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // Get current date if end date is null (present job)
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        // Convert month number to abbreviation
        const startMonthStr = months[start_month - 1];
        const endMonthStr = end_month ? months[end_month - 1] : null; // Don’t set "Present" here yet

        // If still working, use "Present" only once
        const endYearStr = end_year ? end_year : (!end_month ? "Present" : ""); // Condition to avoid duplicate "Present"

        // Calculate experience duration
        const startDate = new Date(start_year, start_month - 1);
        const endDate = end_year ? new Date(end_year, (end_month || 12) - 1) : currentDate;

        let totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth()) + 1;
        let years = Math.floor(totalMonths / 12);
        let monthsDiff = totalMonths % 12;

        // Final formatted string with condition to handle "Present"
        return `${startMonthStr} ${start_year} - ${endMonthStr ? endMonthStr + ' ' : ''}${endYearStr} (${years} yrs ${monthsDiff} mon)`;
    };

    useEffect(() => {
        fetchDataUser();
        fetchExperience();
        fetchEducation();
        fetchLanguage();
    }, []);


    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const downloadPDF = (userDataS, ratingsM) => {
        console.log("ratingsM", ratingsM);

        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Scope to the component's ref to avoid capturing unintended elements
        let elements = pdfRef.current.querySelectorAll(".pdf-section");
        let position = 5; // Reduced initial padding from the top

        const captureElement = (element, callback) => {
            html2canvas(element, {
                scale: 2,
                useCORS: true, // Handle external images
            }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const imgWidth = pdfWidth - 20; // Margins on both sides
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                if (position + imgHeight > pdfHeight - 5) {
                    pdf.addPage();
                    position = 5; // Reduced top padding on new pages
                }

                pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
                position += imgHeight + 2; // Reduced gap between sections from 10 to 2 mm

                callback();
            });
        };

        const processNext = (index) => {
            if (index < elements.length) {
                captureElement(elements[index], () => processNext(index + 1));
            } else {
                pdf.save("profile_review.pdf");
            }
        };

        // Ensure first section is captured properly
        if (elements.length > 0) {
            processNext(0);
        } else {
            alert("No content to generate PDF!");
        }
    };
    // // Expose downloadPDF to the parent via ref
    useImperativeHandle(ref, () => ({
        downloadPDF,
    }));



    return (
        <div style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
            <Container ref={pdfRef} className=" rounded-md p-5 " id="pdf-content">
                {/* Profile Section */}
                <section className="pt-[20px]  pdf-section">
                    <Row className=" justify-between pb-3">
                        <Col lg={12} >
                            <h1 className="text-[35px] font-bold text-black mb-0 leading-tight relative ">Career Report</h1>
                        </Col>
                    </Row>
                    <Row className=" justify-between border-b border-gray-300">
                        <Col lg={6} md={12} sm={12}>
                            <div className="flex items-end space-x-6 mb-3">
                                <img
                                    src={
                                        userDataS?.profile_image
                                            ? `${userDataS.profile_image}`
                                            : `${IMAGE_PATHS.USER}`
                                    }
                                    alt="User"
                                    className="w-[90px] h-[90px] rounded-full object-cover border border-gray-300 relative top-[7px]"
                                />
                                <div className="flex-1 relative leading-[1.5] bottom-[7px]">
                                    <h1 className="text-[28px] font-bold text-black mb-0 leading-tight relative">{userDataS?.name}</h1>
                                    <div className="flex space-x-6 w-max">
                                        <div><i className="fas fa-map-marker-alt mr-1 black-class"></i> <span className="text-gray-700">{locationQuery || "Location not available"}</span></div>
                                        <div><i className="fas fa-phone mr-1 black-class"></i> <span className="text-gray-700">{formValues.phone || "Phone not available"}</span></div>
                                        <div><i className="fas fa-envelope mr-1 black-class"></i> <span className="text-gray-700">{formValues.email || "Email not available"}</span></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} className="mb-6 md:mb-0 leading-[1.4]">
                            <div className="text-center flex justify-end ">

                                <div className="border border-gray-300 rounded-[5px] p-2 ">
                                    <div className="relative bottom-[9px]">
                                        <div className="text-[35px] font-bold leading-[1.2] text-[#000]">{ratingsM.overall_rating.toFixed(1)}</div>
                                        <span className="font-medium text-[18px] text-[#000]">
                                            {getRatingLabel(ratingsM.overall_rating)}
                                        </span>
                                        <div className="flex  mt-0">
                                            <StarRating rating={ratingsM.overall_rating} starSize={15} />
                                        </div>

                                        <span className="text-gray-600 text-[14px]">{ratingsM.total_reviews > 0 ? `${ratingsM.total_reviews} Reviews` : "No Reviews Yet"}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
                <section className="pb-[11px] pdf-section">
                    <Container>
                        {/* Experience Header */}
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between items-baseline">
                                    <div>
                                        <h3 className="text-[22px] font-bold text-gray-900 mb-4 mt-2 leading-[1]">Experience</h3>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        {
                            experiences.map((experience, index) => (
                                <Row
                                    key={index}
                                    className={`bg-white rounded-lg p-6 border-1 ${index !== experiences.length - 1 ? 'mb-[20px]' : ''}`}
                                >
                                    {
                                        experience.overall_rating != null && experience.overall_rating.total_reviews > 0 ? (
                                            <>
                                                <Col lg={2} className="mb-6 md:mb-0 leading-[1.4] !pr-0">
                                                    <div className="text-center flex justify-start ">

                                                        <div className="border border-gray-300 rounded-[5px] p-2 relative top-[10px]">
                                                            <div className="relative bottom-[9px]">
                                                                <div className="text-[35px] font-bold leading-[1.2] text-[#000]">{experience.overall_rating.overall_rating}</div>
                                                                <span className="font-medium text-[18px] text-[#000]">
                                                                    {getRatingLabel(experience.overall_rating.overall_rating)}
                                                                </span>
                                                                <div className="flex  mt-0">
                                                                    <StarRating rating={experience.overall_rating.overall_rating} starSize={15} />
                                                                </div>

                                                                <span className="text-gray-600 text-[14px]">{experience.overall_rating.total_reviews} Reviews</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </>) : (<>

                                            </>)
                                    }
                                    <Col lg={experience.overall_rating.total_reviews > 0 ? 10 : 12} className="leading-[1.4] !pl-0 relative bottom-[2px]">
                                        <h4 className="text-lg font-bold text-gray-900 mb-0">{experience.title}</h4>
                                        <p className="text-gray-700 flex flex-wrap ">
                                            <span> {experience.company_name}
                                            </span><span className="ml-2"> | {experience.employment_type}</span>
                                        </p>
                                        <p className="text-gray-700">{formatExperienceDate(experience.start_month, experience.start_year, experience.end_month, experience.end_year)}</p>
                                        <p className="text-gray-700">{experience.location}</p>
                                        <p className="text-gray-700 mt-2">
                                            {experience.description}
                                        </p>
                                    </Col>
                                </Row>
                            ))}
                    </Container>
                </section>
                <section className="pdf-section">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between items-baseline">
                                    <div>
                                        <h3 className="text-[22px] font-bold text-gray-900 mb-4  mt-2 leading-[1]">Education</h3>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        {
                            education.map((educationVal, index) => (
                                <Row
                                    key={index}
                                    className={`bg-white rounded-lg p-6 border-1 ${index !== educationVal.length - 1 ? 'mb-[20px]' : ''}`}>
                                    {educationVal.overall_rating != null && educationVal.overall_rating.total_reviews > 0 ? (<>
                                        <Col lg={2} className="mb-6 md:mb-0 leading-[1.4] !pr-0">
                                            <div className="text-center flex justify-start ">

                                                <div className="border border-gray-300 rounded-[5px] p-2 relative top-[10px]">
                                                    <div className="relative bottom-[9px]">
                                                        <div className="text-[35px] font-bold leading-[1.2] text-[#000]">{educationVal.overall_rating.overall_rating}</div>
                                                        <span className="font-medium text-[18px] text-[#000]">
                                                            {getRatingLabel(educationVal.overall_rating.overall_rating)}
                                                        </span>
                                                        <div className="flex  mt-0">
                                                            <StarRating rating={educationVal.overall_rating.overall_rating} starSize={15} />
                                                        </div>

                                                        <span className="text-gray-600 text-[14px]">{educationVal.overall_rating.total_reviews} Reviews</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </>) : (<></>)
                                    }
                                    <Col lg={educationVal.overall_rating.total_reviews > 0 ? 10 : 12} className="leading-[1.4] !pl-0  relative bottom-[4px]">
                                        <h4 className="text-lg font-bold text-gray-900 mb-0">{educationVal.school_name}</h4>
                                        <p className="text-gray-700">{educationVal.degree_name}</p>
                                        <p className="text-gray-700 ">{educationVal.start_year} {educationVal.end_year != null ? '-' + educationVal.end_year : ''}</p>
                                        <p className="text-gray-700">Grade: {educationVal.grade}</p>
                                        <p className="text-gray-700">{educationVal.activities}</p>
                                        <p className="text-gray-700 mt-2">
                                            {educationVal.description}
                                        </p>
                                    </Col>
                                </Row>
                            ))
                        }
                    </Container>
                </section>
                <section className="py-3 pt-0 pdf-section">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between items-baseline">
                                    <div>
                                        <h3 className="text-[22px] font-bold text-gray-900 mb-4 mt-2 leading-[1]">Language</h3>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        {
                            language.map((languageVal, index) => (

                                languageVal.has_certificate === 0 ? (

                                    <Row className={`bg-white rounded-lg p-6 border-1 ${index !== languageVal.length - 1 ? 'mb-[20px]' : ''}`}>
                                        <Col lg={10} md={4} className="mb-2">
                                            <h4 className="text-lg font-bold text-gray-900 mb-0">{languageVal.language_name}</h4>
                                            <p className="text-gray-500 text-sm font-semibold flex items-center">
                                                <span className="mr-2">{languageVal.level_name}</span>
                                            </p>
                                        </Col>

                                    </Row>
                                ) : (

                                    <Row className={`bg-white rounded-lg p-6 border-1 ${languageVal.has_certificate.length > 1 ? 'mb-[20px]' : ''}`}>
                                        <Col lg={10} md={4} className="mb-2">
                                            <h4 className="text-lg font-bold text-gray-900 mb-0">{languageVal.language_name}</h4>
                                            <p className="font-bold text-gray-900 text-sm">{languageVal.test_name}</p>
                                            <p className="text-gray-500 text-sm font-semibold flex items-center">
                                                <span className="mr-2">{months[languageVal.completion_month]} {languageVal.completion_year}</span>
                                                <span className="px-2 py-[2px] text-xs font-medium bg-black text-white rounded-full flex items-center relative top-[12px]">
                                                    <i className="fas fa-check-circle yellow-class mr-1 relative bottom-[12px]"></i>
                                                    <span className="relative bottom-[12px]">Certified</span>
                                                </span>
                                            </p>
                                        </Col>

                                        <hr className="border-gray-300 w-full my-3" />

                                        <Col lg={3} md={12} className="text-start">
                                            <div className="box-total w-j !pb-[5px]">
                                                <h4 className="text-lg font-bold yellow-class mb-0 text-center relative bottom-[12px]">Total</h4>
                                                <h1 className="text-[40px] font-bold yellow-class leading-[1] mb-0 relative bottom-[3px]">{Math.round(languageVal.total_score)}</h1>
                                            </div>

                                        </Col>
                                        <Col lg={9} md={12} sm={12} className="flex flex-col justify-center">
                                            <div className="grid grid-cols-2 md:grid-cols-2 gap-y-2 gap-x-4 w-full">
                                                {[
                                                    { label: "Reading", score: Math.round(languageVal.reading_score) },
                                                    { label: "Writing", score: Math.round(languageVal.writing_score) },
                                                    { label: "Listening", score: Math.round(languageVal.listening_score) },
                                                    { label: "Speaking", score: Math.round(languageVal.speaking_score) },
                                                ].map((item, index) => (
                                                    <div key={index} className="flex items-center justify-between w-full">
                                                        {/* Label */}
                                                        <span className="text-gray-900 text-sm font-medium w-[25%]">{item.label}</span>

                                                        {/* Progress Bar */}
                                                        <div className="w-[100%] h-[12px] bg-gray-200 rounded-full overflow-hidden relative top-[13px]">
                                                            <div className="h-full yellow-b rounded-full" style={{ width: `${item.score}%` }}></div>
                                                        </div>

                                                        {/* Score Value */}
                                                        <span className="text-gray-900 text-sm font-medium w-[15%] text-end ">{item.score}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>

                                )
                            ))
                        }
                    </Container>
                </section>


                <section className=" pt-0 pb-[30px] pdf-section">
                    <Container>

                        <Row className="custm-bg  rounded-lg p-6 border ">
                            <Col lg={2} >
                                <div className="text-center flex relative top-[4px]">
                                    <img
                                        src={IMAGE_PATHS.LOGO}
                                        className="w-[70px] h-[70px] "
                                    />
                                </div>
                            </Col>
                            <Col lg={8} >
                                <p className="text-gray-500 text-sm font-semibold flex items-center justify-center">
                                    <span className="mr-2 relative top-[8px]">Scan the QR code to explore more details at JobCritics.com</span>
                                </p>
                            </Col>
                            <Col lg={2} >
                                <div className="text-center flex justify-end relative top-[4px]">
                                    <p className="font-medium text-[10px] text-[#000] mr-[10px] leading-[1.5]">Scan QR Code<br /> For  Detailed<br /> Career Report</p>
                                    <img
                                        src="https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=https://example.com"
                                        alt="QR Code"
                                        className="w-[70px] h-[70px] border border-gray-300 rounded-md"
                                    />
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </section>


                {/* Download Button */}
                <Row className="mt-6">
                    <Col lg={12} className="text-center">
                        <button onClick={downloadPDF} className="px-4 py-2 bg-blue-600 text-white rounded-md font-semibold hover:bg-blue-700 transition">
                            Download PDF
                        </button>
                    </Col>
                </Row>

            </Container>
        </div>


    );
});

export default ProfileReviewPDF;