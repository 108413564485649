export const getStarColorClass = (rating) => {
    if (rating >= 4.5) return "yellow-i"; // Excellent
    if (rating >= 4.0 && rating < 4.5) return "green-i"; // Very Good
    if (rating >= 3.0 && rating < 4.0) return "greenlite-i"; // Average
    if (rating >= 2.0 && rating < 3.0) return "redlite-i"; // Poor
    return "red-i"; // Terrible (rating < 2.0)
};

export const getRatingLabel = (rating) => {
    if (rating >= 4.5) return "Excellent";
    if (rating >= 4.0 && rating < 4.5) return "Good";
    if (rating >= 3.0 && rating < 4.0) return "Average";
    if (rating >= 1.0 && rating < 3.0) return "Poor";
    return "No Rating"; // rating < 2.0
};