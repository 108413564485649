import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import API from "../../Api/Api"; // Adjust path as needed
import {  base64Encode,base64Decode } from "../../Functions/Constant";
import { useParams } from "react-router-dom";
const CopyLink = () => {
  const { id } = useParams();
  const [userId, setUserId] = useState(0);
  const [rateType, setRateType] = useState(0);
  const [dataTypeId, setDataTypeId] = useState(0);
  const [jwtTokenJobcritic, setjwtTokenJobcritic] = useState(localStorage.getItem("jwt_token_jobcritic"));
  const navigate = useNavigate();
  //const navigate = useNavigate();
const rateNow = async (payload) => {
    const response = await API.post("/user/initiate-rating", payload);

    if (response && response.review_id) {
      const encodedReviewId = base64Encode(response.review_id.toString());
      window.location.href = `/review_form/${encodedReviewId}`;
    } else {
      console.error("Review ID not found in response", response);
    }
};
useEffect(() => {
  const decodeId = base64Decode(id);
  const decodedId = decodeId.split("-");
  const userId = parseInt(decodedId[0], 10);
  const rateType = parseInt(decodedId[1], 10);
  const dataTypeId = parseInt(decodedId[2], 10);

  if (jwtTokenJobcritic) {
    setUserId(userId);
    setRateType(rateType);
    setDataTypeId(dataTypeId);
    // console.log("Decoded ID:", decodedId);
    // console.log("User ID:", userId);
    // console.log("Rate Type:", rateType);
    // console.log("Data Type ID:", dataTypeId);
    rateNow({
      user_id: userId,
      type: rateType,
      tag_data_id: dataTypeId,
    });
    //console.log("window.location.pathname:", window.location.pathname);
    localStorage.removeItem("redirectPath_jobcritic");
  } else {
    localStorage.setItem("redirectPath_jobcritic", window.location.pathname);
    navigate("/login");
  }

  console.log('jwtTokenJobcritic', jwtTokenJobcritic);
 

        //console.log("Review ID:", id, reviewId, base64Decode(id));

        // const fetchReview = async () => {
        //     try {
        //         const response = await API.get(`/review_form/${id}`);
        //         if (response.success) {
        //             setReview(response.review);
        //         } else {
        //             setError("Review not found.");
        //         }
        //     } catch (error) {
        //         setError("Failed to load review.");
        //         console.error("Error fetching review:", error);
        //     } finally {
        //         setLoading(false);
        //     }
        // };

        // fetchReview();
    }, [id]);
  // useEffect(() => {
  //   const initiateRatingFlow = async () => {
  //     const params = new URLSearchParams(window.location.search);
  //     const search_type = parseInt(params.get("search_type"), 10); // Expect 1 or 2

  //     if (! !search_type) {
  //       console.error("Missing token or search_type in URL");
  //       return;
  //     }

  //     try {
  //       // localStorage.setItem("jwt_token_jobcritic", token);
  //       // const user = jwtDecode(token);
  //       // console.log("User Info:", user);

  //       // const tag_data_id = search_type === 1 ? user.company_id : user.school_id;
  //       // const payload = {
  //       //   user_id: user.user_id,
  //       //   type: search_type,
  //       //   tag_data_id,
  //       // };

  //       // const response = await API.post("/user/initiate-rating", payload);

  //       // if (response && response.review_id) {
  //       //   const encodedReviewId = base64Encode(response.review_id.toString());
  //       //   window.location.href = `/review_form/${encodedReviewId}`;
  //       //   //window.location.href = `/review_form/${encodedReviewId}`;
  //       // } else {
  //       //   console.error("Review ID not found in response", response);
  //       // }
  //     } catch (error) {
  //       console.error("Error during rating flow:", error);
  //     }
  //   };

  //   initiateRatingFlow();
  // }, [navigate]);

  return null;
};

export default CopyLink;
