import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";


const AuthSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      localStorage.setItem("jwt_token_jobcritic", token);
      const decoded = jwtDecode(token);
      console.log("User Info:", decoded);
      if(localStorage.getItem("redirectPath_jobcritic")){
        window.location.href = localStorage.getItem("redirectPath_jobcritic"); 
      }else{
        window.location.href = "/feeds"; // Page reload ke saath redirect karega
      }
     
    }
  }, [navigate]);

  return ;
};

export default AuthSuccess;
