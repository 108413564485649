import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainHeader from "../../Pages/Header/MainHeader";
import FooterStyle from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import API from '../../Api/Api';
import Chatbot from "./Chatbot";
import { IMAGE_PATHS, base64Encode, base64Decode, DOMAIN_URL } from "../../Functions/Constant";
const dummyData = [
    {
        name: "Ramandeep Singh",
        company: "Exoways Web Technologies Pvt. Ltd. | Full-time",
        duration: "Oct 2018 - Present 6 yrs 6 mon",
        location: "Chandigarh",
        image: IMAGE_PATHS.USER,
        relation: "Worked Together",
    },

];

const SearchForm = () => {
    const location = useLocation();
    const [users, setUsers] = useState([]);
    const [visibleItems, setVisibleItems] = useState(5);
    const [queryString, setQueryString] = useState({});
    const getQueryParams = () => {
        const searchParams = new URLSearchParams(location.search);
        const queryData = searchParams.get("query");

        try {
            return queryData ? JSON.parse(decodeURIComponent(queryData)) : {};
        } catch (error) {
            console.error("Error parsing JSON from query string:", error);
            return {};
        }
    };
    const fetchUserList = async (payload) => {
        const response = await API.post('/user/searchuser', payload);
        const dataArr = response.users;
        // dataArr.push({
        //     name: "Ramandeep Singh",
        //     company: "Exoways Web Technologies Pvt. Ltd. | Full-time",
        //     duration: "Oct 2018 - Present 6 yrs 6 mon",
        //     location: "Chandigarh",
        //     image: IMAGE_PATHS.AVATAR,
        //     relation: "Worked Together",
        // });

        setUsers(dataArr);
        console.log(response.users);
    }
    useEffect(() => {
        const searchParams = getQueryParams();
        setQueryString(searchParams);
        console.log(searchParams);
        fetchUserList(searchParams);
    }, [])
    const rateNow = async (user) => {
        const tag_data_id = queryString.search_type == 1 ? user.company_id : user.school_id;
        const payload = {
            user_id: user.user_id, type: queryString.search_type, tag_data_id: tag_data_id
        };
        const response = await API.post('/user/initiate-rating', payload);
        console.log(response);
        window.location.href = '/review_form/' + base64Encode(response.review_id);
    }
    const rateUser = async (user) => {
        const payload = {
            name: user.name,
            phone: user.phone || "",
            email: user.email || "",
            city: user.city || "",
            company: user.company || "",
            search_type: user.relation === "Work" ? 1 : 2,
            company_year: user.relation === "Work" ? user.year : "",
            school: user.relation !== "Work" ? user.institution : "",
            school_year: user.relation !== "Work" ? user.year : "",
            user_id: user.user_id,
        };

        try {
            const response = await API.post('/user/usercreate-for-rating', payload);
            window.location.href = '/review_form/' + base64Encode(response.review_id);
        } catch (error) {
            console.error("Error creating user rating:", error);
        }
    };


    const loadMore = () => {
        setVisibleItems((prev) => prev + 5);
    };
    const formatExperienceDate = (start_month, start_year, end_month, end_year) => {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // Get current date if end date is null (present job)
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        // Convert month number to abbreviation
        const startMonthStr = months[start_month - 1];
        const endMonthStr = end_month ? months[end_month - 1] : "Present";

        // If still working
        const endYearStr = end_year ? end_year : "Present";

        // Calculate experience duration
        const startDate = new Date(start_year, start_month - 1);
        const endDate = end_year ? new Date(end_year, (end_month || 12) - 1) : currentDate;

        let totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
        let years = Math.floor(totalMonths / 12);
        let monthsDiff = totalMonths % 12;

        // Final formatted string
        return `${startMonthStr ? startMonthStr : months[0]} ${start_year} - ${endMonthStr} ${endYearStr} (${years} yrs ${monthsDiff} mon)`;
    };
    const startLoader = () => {
        //setIsMainLoading(true);
    }
    return (
        <div>
            <MainHeader />
            <section className="pb-[190px] px-4 pt-[90px] custm-bg">
                <Container>
                    <h3 className="text-[32px] font-bold text-black mb-10 leading-[1.5]">
                        {users.length} results matching
                    </h3>

                    {users.slice(0, visibleItems).map((user, index) => (
                        <Row key={index} className="p-6 bg-white mb-2 rounded-lg border-1">
                            <Col lg={6} md={12} sm={12} >
                                <div className="flex">
                                    <img
                                        src={user.profile_picture != null ? DOMAIN_URL + user.profile_picture : IMAGE_PATHS.USER}
                                        className="w-[80px] h-[80px] rounded-full object-cover border border-gray-300"
                                        alt={user.name}
                                    />
                                    <div className="flex flex-col justify-center ml-4">
                                        <h4 className="text-lg font-bold text-gray-900 mb-0">
                                            {user.name}
                                        </h4>
                                        {
                                            queryString.search_type == 1 ? (
                                                <><p className="text-gray-700 leading-[1.6]">{user.company_name}</p>
                                                    <p className="text-gray-700 leading-[1.2]">{formatExperienceDate(user.start_month, user.start_year, user.end_month, user.end_year)}</p></>
                                            ) : (<><p className="text-gray-700 leading-[1.6]">{user.school_name}</p>
                                                <p className="text-gray-700 leading-[1.2]">{user.school_year}</p></>)
                                        }

                                        <p className="text-gray-700">{user.city_name}</p>

                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} md={12} sm={12} className="flex justify-end items-center">
                                <button className="btn-explore-white" onClick={() => rateNow(user)}>Review Now</button>
                            </Col>
                        </Row>
                    ))}


                    {queryString && Object.keys(queryString).length > 0 && (
                        <>
                            <Row className="pt-[40px] pb-[12px]">
                                <Col lg={12} md={12} sm={12}>
                                    <h4 className="text-lg font-bold text-gray-900 mb-0">
                                        This field is automatically generated by the chatbot based on the details provided.
                                        Would you like to proceed ?

                                    </h4>
                                </Col>
                            </Row>

                            <Row className="p-6 bg-white mb-2 rounded-lg border-1">
                                <Col lg={6} md={12} sm={12}>
                                    <div className="flex">
                                        <img
                                            src={IMAGE_PATHS.USER}
                                            className="w-[80px] h-[80px] rounded-full object-cover border border-gray-300"
                                            alt="Search Query"
                                        />
                                        <div className="flex flex-col justify-center ml-4">
                                            <h4 className="text-lg font-bold text-gray-900 mb-0">
                                                {queryString.name}
                                            </h4>

                                            {queryString.search_type == 1 ? (
                                                <>
                                                    {queryString.company && (
                                                        <p className="text-gray-700 leading-[1.6]">
                                                            {queryString.company}
                                                        </p>
                                                    )}

                                                    {queryString.company_year && (
                                                        <p className="text-gray-700 leading-[1.2]">
                                                            {formatExperienceDate(null, queryString.company_year, null, queryString.company_year)}
                                                        </p>
                                                    )}

                                                </>
                                            ) : (
                                                <>
                                                    {queryString.school && (
                                                        <p className="text-gray-700 leading-[1.6]">
                                                            {queryString.school}
                                                        </p>
                                                    )}

                                                    {queryString.school_year && (
                                                        <p className="text-gray-700 leading-[1.2]">
                                                            {formatExperienceDate(null, queryString.school_year, null, queryString.school_year)}
                                                        </p>
                                                    )}

                                                </>
                                            )}

                                        </div>
                                    </div>
                                </Col>

                                <Col lg={6} md={12} sm={12} className="flex justify-end items-center">
                                    <button
                                        className="btn-explore-white"
                                        onClick={() => {
                                            const pseudoUser = {
                                                name: queryString.name || "Search Result",
                                                phone: queryString.phone || "", // if available
                                                email: queryString.email || "", // if available
                                                city: queryString.city || '',
                                                company: queryString.company || '',
                                                relation: queryString.relation || "Work", // default to "Work" if not specified
                                                year: queryString.company_year || queryString.school_year || "",
                                                institution: queryString.school || "",
                                                user_id: queryString.user_id || null, // if needed
                                            };

                                            rateUser(pseudoUser);
                                        }}
                                    >
                                        Continue
                                    </button>

                                </Col>
                            </Row>
                        </>
                    )}


                    {visibleItems < dummyData.length && (
                        <div className="text-center mt-4">
                            <button
                                className="text-lg font-semibold text-gray-600 cursor-pointer"
                                onClick={loadMore}
                            >
                                Load more search
                            </button>
                        </div>
                    )}
                </Container>

            </section>
            <section className="custm-bg">
                <Container>
                    <Row className="justify-center pb-[60px]">
                        <Chatbot startLoader={startLoader} chat="1" />
                    </Row>
                </Container>
            </section>

            <FooterStyle theme="light" className="bg-black text-[#828282]" />
        </div >
    );
};

export default SearchForm;
