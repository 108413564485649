import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Chatbot.css";
import { Col, Container, Row, InputGroup, FormControl, ListGroup } from "react-bootstrap";
import API from '../../Api/Api';
import { IMAGE_PATHS, base64Encode, base64Decode, DOMAIN_URL } from "../../Functions/Constant";
import Buttons from '../../Components/Button/Buttons';

const Chatbot = ({ startLoader,chat}) => {
    const [chatOpen, setChatOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [userInput, setUserInput] = useState("");
    const [userResponses, setUserResponses] = useState({});
    const [isLastQuestion, setIsLastQuestion] = useState(false);
    const [userName, setUserName] = useState("");
    const [questions, setQuestions] = useState([
        { text: "Hi! Who are you looking for?", type: "input", key: "name", placeholder: "Type Name" },
        { text: "Got it! Please enter their phone number.", type: "input", key: "phone", placeholder: "Type Phone Number" },
    ]);
    const [isScrolled, setIsScrolled] = useState(false);
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Phone validation - only numbers, max 40 digits
    const phoneRegex = /^[0-9]{1,20}$/;

    const chatRef = useRef(null);
    const chatBoxRef = useRef(null);
    const inputRef = useRef(null);
    const sendButtonRef = useRef(null);
    const backButtonRef = useRef(null);
    const optionRefs = useRef([]);

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
        const lastMessage = messages[messages.length - 1];
        if (lastMessage && lastMessage.showInput && inputRef.current) {
            inputRef.current.focus();
        }
    }, [messages]);

    const handleSearch = async () => {
        setChatOpen(true);
        const firstQuestion = "Hi! Who are you looking for?";
        setTimeout(() => {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: firstQuestion, sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), placeholder: "Type Name" }
            ]);
            setCurrentStep(1);
        }, 1000);
    };

    // const handleBack = () => {
    //     if (currentStep <= 1) return;

    //     const previousStep = currentStep - 1;
    //     const previousQuestion = questions[previousStep - 1];

    //     if (!previousQuestion) return;

    //     setCurrentStep(previousStep);
    //     setMessages(prevMessages => {
    //         const updatedMessages = prevMessages.map((msg, index) =>
    //             index === prevMessages.length - 1 ? { ...msg, showInput: false } : msg
    //         );
    //         return [
    //             ...updatedMessages,
    //             { text: "Back", sender: "user", time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), isUserResponse: true, animate: true },
    //             { text: previousQuestion.text, sender: "bot", type: previousQuestion.type, showInput: true, options: previousQuestion.options || null, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
    //         ];
    //     });

    //     setUserResponses(prevResponses => {
    //         const newResponses = { ...prevResponses };
    //         delete newResponses[questions[currentStep - 1].key];
    //         return newResponses;
    //     });
    // };
    const handleBack = () => {
        if (currentStep <= 1) return; // Prevent going back before the first question
    
        const previousStep = currentStep - 1;
        const previousQuestion = questions[previousStep - 1];
    
        if (!previousQuestion) return; // Safety check for undefined questions
    
        // Revert to the previous step
        setCurrentStep(previousStep);
    
        // Remove the current question from the questions array
        setQuestions(prevQuestions => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions.splice(previousStep, 1); // Remove the current question
            return updatedQuestions;
        });
    
        // Update messages: hide the current input and show the previous question
        setMessages(prevMessages => {
            const updatedMessages = prevMessages.map((msg, index) =>
                index === prevMessages.length - 1 ? { ...msg, showInput: false } : msg
            );
            const previousResponse = userResponses[previousQuestion.key]; // Get previous response if exists
            return [
                ...updatedMessages,
                { 
                    text: "Back", 
                    sender: "user", 
                    time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), 
                    isUserResponse: true, 
                    animate: true 
                },
                { 
                    text: previousQuestion.text, 
                    sender: "bot", 
                    type: previousQuestion.type, 
                    showInput: true, 
                    options: previousQuestion.options || null, 
                    time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), 
                    animate: true,
                    is_skipbutton: previousQuestion.is_skipbutton || false, // Preserve skip button if present
                    placeholder: previousQuestion.placeholder || "Type here...", // Preserve placeholder
                    founddata:previousQuestion.founddata || null // Preserve found data if present
                }
            ];
        });
    
        // Remove the current step's response from userResponses
        setUserResponses(prevResponses => {
            const newResponses = { ...prevResponses };
            delete newResponses[questions[currentStep - 1].key]; // Remove current step's response
            return newResponses;
        });
    
        // Reset userInput to allow re-entry
        setUserInput("");
    };
    

    const searchWithData = async (data) => {
        try {
            const payload = {
                name: data.name || "",
                phone: data.phone || "",
                email: data.email || "",
                city: data.city || "",
                search_type: data.relation === "Work" ? 1 : data.relation === "Study" ? 2 : null,
                company: data.company || "",
                company_year: data.year || "",
                school: data.institution || "",
                school_year: data.year || ""
            };
            if(data.search_type === 0) {
                const response = await API.post('/user/searchuserphone', payload);
                return response.users;
            }else if(data.search_type === -1) {
                const response = await API.post('/user/searchuseremail', payload);
                return response.users;
            }else{
                const response = await API.post('/user/searchuser', payload);
                return response;
            }
            
        } catch (error) {
            console.error("Search error:", error);
            return [];
        }
    };

    const handleResponse = async (response) => {
        if (!response.trim()) return;

        const currentQuestion = questions[currentStep - 1];
        setUserResponses(prevResponses => ({
            ...prevResponses,
            [currentQuestion.key]: response
        }));

        setMessages(prevMessages => [
            ...prevMessages.map((msg, index) =>
                index === prevMessages.length - 1 ? { ...msg, showInput: false } : msg
            ),
            { text: response, sender: "user", time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), isUserResponse: true, animate: true }
        ]);
        setUserInput("");
        console.log("userStep", currentStep);
        console.log("userResponse", response);
        // console.log("userResponses", userResponses);
        console.log("currentQuestion", currentQuestion);
        console.log("questions", questions);
        if (currentStep === 1) { // Name
            setUserName(response);
            //setQuestions(prev => [...prev, { text: "Got it! Do you have their phone number?", type: "options", options: ["No","Yes"], key: "hasPhone" }]);
            setCurrentStep(prevStep => prevStep + 1);
            setTimeout(() => {
                setMessages(prevMessages => [
                    ...prevMessages,
                   // { text: "Got it! Do you have their phone number?", sender: "bot", type: "options", options: ["No","Yes"], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                    { text: "Got it! Please enter their phone number.", sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true, is_skipbutton: true, placeholder: "Type Phone Number",key: "phone" }
                ]);
            }, 1000);
        } else if (currentStep === 2 ) { // Do you have phone?
            if (response === "Skipped") {
                setQuestions(prev => [...prev, { text: "Okay! Please enter their email.", type: "input", key: "email" }]);
                setMessages(prevMessages => [
                    ...prevMessages,
                   // { text: "Okay! Do you have their email?", sender: "bot", type: "options", options: ["No","Yes"], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                    { text: "Okay! Please enter their email.", sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true, is_skipbutton: true,placeholder: "Type Email" }
                ]);
                setCurrentStep(prevStep => prevStep + 1);
            }else{
                setMessages(prevMessages => [
                    ...prevMessages,
                    { search_status:1, text: "Searching… 🔍", sender: "bot", time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                ]);
                const searchResult = await searchWithData({ ...userResponses, phone: response, search_type: 0 });
                console.log("searchResult", searchResult);
                setTimeout(() => {
                    if (searchResult.length > 0) {
                        const details = searchResult.map(user => ` ${user.name || userName} (${user.city_name || "Unknown city"})`).join(", ");
                        setMessages(prevMessages => [
                            ...prevMessages,
                            { founddata:searchResult[0], text: `I found a match! ${details}. Would you like to proceed?`, sender: "bot", type: "options", options: ["No","Yes"], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                        ]);
                        setQuestions(prev => [...prev, {founddata:searchResult[0], text: `I found a match! ${details}. Would you like to proceed?`, type: "options", options: ["No","Yes"], key: "proceedPhone" }]);
                        setCurrentStep(prevStep => prevStep + 1);
                    } else {
                        setMessages(prevMessages => [
                            ...prevMessages,
                            { 
                                text: "I couldn’t find a match. Please enter their email.", sender: "bot", type: "input", key: "hasEmailAfterPhone", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true, is_skipbutton: true , placeholder: "Type Email"
                            }]);
                        setQuestions(prev => [...prev, { text: "I couldn’t find a match. Please enter their email.", type: "input", key: "hasEmailAfterPhone" }]);
                        setCurrentStep(prevStep => prevStep + 1);
                    }
                }, 1000);
            } 
        }else if ((currentStep === 3 && currentQuestion.key === "hasEmailAfterPhone") || (currentStep === 3 && currentQuestion.key === "email") || (currentStep === 4 && currentQuestion.key === "email")) { // Email after no phone
            if (response === "Skipped") {
                setQuestions(prev => [...prev, { text: "Got it! Can you share their city?", type: "input", key: "city" }]);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: "Got it! Can you share their city?", sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true, placeholder: "Type City" }
                ]);
                setCurrentStep(prevStep => prevStep + 1);
            }else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { search_status:1,text: "Searching… 🔍", sender: "bot", time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                ]);
                const searchResult = await searchWithData({ ...userResponses, email: response, search_type: -1 });
                setTimeout(() => {
                    if (searchResult.length > 0) {
                        const details = searchResult.map(user => `${user.name || userName} (${user.city || "Unknown city"})`).join(", ");
                        setMessages(prevMessages => [
                            ...prevMessages,
                            {  founddata:searchResult[0], text: `I found a match! ${details}. Would you like to proceed?`, sender: "bot", type: "options", options: ["No","Yes"], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                        ]);
                        setQuestions(prev => [...prev, {founddata:searchResult[0], text: `I found a match! ${details}. Would you like to proceed?`, type: "options", options: ["No","Yes"], key: "proceedEmail" }]);
                        setCurrentStep(prevStep => prevStep + 1);
                    } else {
                        setMessages(prevMessages => [
                            ...prevMessages,
                            { text: "Still no match. Can you share their city?", sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true,placeholder: "Type City" }
                        ]);
                        setQuestions(prev => [...prev, { text: "Still no match. Can you share their city?", type: "input", key: "city" }]);
                        setCurrentStep(prevStep => prevStep + 1);
                    }
                }, 1000);
            } 
        } else if (currentStep === 3 && currentQuestion.key === "proceedPhone") { // Proceed after phone match
            if (response === "Yes") {
                setQuestions(prev => [...prev, { text: "Great! Did you study together or work together?", type: "options", options: ["Study", "Work"], key: "relation" }]);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: "Great! Did you study together or work together?", sender: "bot", type: "options", options: ["Study", "Work"], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                ]);
                setCurrentStep(prevStep => prevStep + 1);
            } else {
                setQuestions(prev => [...prev, { text: "Okay! Please enter their email.", type: "input", key: "email" }]);
                setMessages(prevMessages => [
                    ...prevMessages,
                   // { text: "Okay! Do you have their email?", sender: "bot", type: "options", options: ["No","Yes"], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                    { text: "Okay! Please enter their email.", sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true, is_skipbutton: true,placeholder: "Type Email" }
                ]);
                setCurrentStep(prevStep => prevStep + 1);
                // setQuestions(prev => [...prev, { text: "Do you have their email?", type: "options", options: ["No","Yes"], key: "hasEmailAfterPhone" }]);
                // setMessages(prevMessages => [
                //     ...prevMessages,
                //     { text: "Do you have their email?", sender: "bot", type: "options", options: ["No","Yes"], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                // ]);
                // setCurrentStep(prevStep => prevStep + 1);
            }
        } else if ((currentStep === 4 && currentQuestion.key === "hasEmailAfterPhone") || (currentStep === 5 && currentQuestion.key === "hasEmailAfterPhone")) { // Email after no match or no proceed
            if (response === "Yes") {
                setQuestions(prev => [...prev, { text: "Please enter their email.", type: "input", key: "email" }]);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: "Please enter their email.", sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true,placeholder: "Type Email" }
                ]);
                setCurrentStep(prevStep => prevStep + 1);
            } else {
                setQuestions(prev => [...prev, { text: "Got it! Can you share their city?", type: "input", key: "city" }]);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: "Got it! Can you share their city?", sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true,placeholder: "Type City" }
                ]);
                setCurrentStep(prevStep => prevStep + 1);
            }
        } else if ((currentStep === 4 && currentQuestion.key === "email") || (currentStep === 5 && currentQuestion.key === "email") || (currentStep === 6 && currentQuestion.key === "email")) { // Email input
            setMessages(prevMessages => [
                ...prevMessages,
                {search_status:1, text: "Searching… 🔍", sender: "bot", time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
            ]);
            const searchResult = await searchWithData({ ...userResponses, email: response, search_type: -1 });
            setTimeout(() => {
                if (searchResult.length > 0) {
                    const details = searchResult.map(user => `${user.name || userName} (${user.city || "Unknown city"})`).join(", ");
                    setMessages(prevMessages => [
                        ...prevMessages,
                        {  founddata:searchResult[0], text: `I found a match! ${details}. Would you like to proceed?`, sender: "bot", type: "options", options: ["No","Yes"], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                    ]);
                    setQuestions(prev => [...prev, {founddata:searchResult[0], text: `I found a match! ${details}. Would you like to proceed?`, type: "options", options: ["No","Yes"], key: "proceedEmail" }]);
                    setCurrentStep(prevStep => prevStep + 1);
                } else {
                    setMessages(prevMessages => [
                        ...prevMessages,
                        { text: "Still no match. Can you share their city?", sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true,placeholder: "Type City" }
                    ]);
                    setQuestions(prev => [...prev, { text: "Still no match. Can you share their city?", type: "input", key: "city" }]);
                    setCurrentStep(prevStep => prevStep + 1);
                }
            }, 1000);
        } else if ((currentStep === 4 && currentQuestion.key === "proceedEmail") || (currentStep === 5 && currentQuestion.key === "proceedEmail") || (currentStep === 6 && currentQuestion.key === "proceedEmail") || (currentStep === 7 && currentQuestion.key === "proceedEmail")) { // Proceed after email match
            if (response === "Yes") {
                setQuestions(prev => [...prev, { text: "Great! Did you study together or work together?", type: "options", options: ["Study", "Work"], key: "relation" }]);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: "Great! Did you study together or work together?", sender: "bot", type: "options", options: ["Study", "Work"], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                ]);
                setCurrentStep(prevStep => prevStep + 1);
            } else {
                setQuestions(prev => [...prev, { text: "Can you share their city?", type: "input", key: "city" }]);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: "Can you share their city?", sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true,placeholder: "Type City" }
                ]);
                setCurrentStep(prevStep => prevStep + 1);
            }
        } else if ((currentStep === 4 && currentQuestion.key === "city") || (currentStep === 5 && currentQuestion.key === "city") || (currentStep === 6 && currentQuestion.key === "city") || (currentStep === 7 && currentQuestion.key === "city") || (currentStep === 8 && currentQuestion.key === "city")) { // City input
            setQuestions(prev => [...prev, { text: "Great! Did you study together or work together?", type: "options", options: ["Study", "Work"], key: "relation" }]);
            setMessages(prevMessages => [
                ...prevMessages,
                { text: "Great! Did you study together or work together?", sender: "bot", type: "options", options: ["Study", "Work"], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
            ]);
            setCurrentStep(prevStep => prevStep + 1);
        } else if ((currentStep === 4 && currentQuestion.key === "relation") || (currentStep === 5 && currentQuestion.key === "relation") || (currentStep === 6 && currentQuestion.key === "relation") || (currentStep === 7 && currentQuestion.key === "relation") || (currentStep === 8 && currentQuestion.key === "relation") || (currentStep === 9 && currentQuestion.key === "relation")) { // Study/Work
            const newQuestion = response === "Study"
                ? { text: "Got it! Can you share the school name?", type: "input", key: "institution", placeholder: "Type School Name" }
                : { text: "Got it! Can you share the company name?", type: "input", key: "company", placeholder: "Type Company Name" };
            setQuestions(prev => [...prev, newQuestion]);
            setMessages(prevMessages => [
                ...prevMessages,
                { text: newQuestion.text, sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true,placeholder: newQuestion.placeholder }
            ]);
            setCurrentStep(prevStep => prevStep + 1);
        } else if ( (currentStep === 5 && currentQuestion.key === "company") || (currentStep === 5 && currentQuestion.key === "institution") ||(currentStep === 6 && currentQuestion.key === "company") || (currentStep === 6 && currentQuestion.key === "institution") || (currentStep === 7 && currentQuestion.key === "institution") || (currentStep === 7 && currentQuestion.key === "company") || (currentStep === 8 && currentQuestion.key === "institution") || (currentStep === 8 && currentQuestion.key === "company") || (currentStep === 9 && currentQuestion.key === "institution") || (currentStep === 9 && currentQuestion.key === "company") || (currentStep === 10 && currentQuestion.key === "institution") || (currentStep === 10 && currentQuestion.key === "company")) { // School/Company
            setQuestions(prev => [...prev, { text: "And which year were you together?", type: "year", key: "year" }]);
            setMessages(prevMessages => [
                ...prevMessages,
                { text: "And which year were you together?", sender: "bot", type: "year", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
            ]);
            setCurrentStep(prevStep => prevStep + 1);
        } else if ((currentStep === 6 && currentQuestion.key === "year") || (currentStep === 7 && currentQuestion.key === "year") || (currentStep === 8 && currentQuestion.key === "year") || (currentStep === 9 && currentQuestion.key === "year") || (currentStep === 10 && currentQuestion.key === "year") || (currentStep === 11 && currentQuestion.key === "year")) { // Year
            const searchResult = await searchWithData({ ...userResponses, year: response });
            // if (searchResult.length > 1) {
            //     setMessages(prevMessages => [
            //         ...prevMessages,
            //         { text: `I found ${searchResult.length} matches. Would you like to see them?`, sender: "bot", type: "options", options: ["No","Yes"], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
            //     ]);
            //     setQuestions(prev => [...prev, { text: `I found ${searchResult.length} matches. Would you like to see them?`, type: "options", options: ["No","Yes"], key: "proceedMatches" }]);
            //     setCurrentStep(prevStep => prevStep + 1);
            // } else 
            if (searchResult.users.length > 0) {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: `I found ${searchResult.users.length>1?searchResult.users.length+' matches':'a match'}! Click Continue to rate them.`, sender: "bot", type: "staticOptions", options: [{ text: "Search Again", user: { user_id: -1 } },{ text: "Continue", user: { searchResult:searchResult, user_id: searchResult.users[0].user_id, payload: { ...userResponses, year: response } } }], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                ]);
                setIsLastQuestion(true);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: `Sorry, I couldn’t find ${userName}, but don’t worry—you can still submit a review for them. Would you like to proceed?`, sender: "bot", type: "staticOptions", options: [{ text: "Search Again", user: { user_id: -1 } },{ text: "Yes", user: { user_id: 0, payload: { ...userResponses, year: response } } }], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                ]);
                setIsLastQuestion(true);
            }
        } else if (currentQuestion.key === "proceedMatches") { // Multiple matches
            if (response === "Yes") {
                const searchResult = await searchWithData(userResponses);
                const options = searchResult.map(user => ({ text: "Continue", user: { user_id: user.user_id, payload: userResponses } }));
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: `${searchResult.length} matches found. Click Continue to rate them.`, sender: "bot", type: "staticOptions", options, showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                ]);
                setIsLastQuestion(true);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: `Sorry, I couldn’t find ${userName}, but don’t worry—you can still submit a review for them. Would you like to proceed?`, sender: "bot", type: "staticOptions", options: [{ text: "Search Again", user: { user_id: -1 } },{ text: "Yes", user: { user_id: 0, payload: userResponses } }], showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), animate: true }
                ]);
                setIsLastQuestion(true);
            }
        }
    };

    const handleContinue = async (user) => {
        if (user.searchResult && user.searchResult.users.length>0 && user.searchResult.send_rate==1 ) {

            startLoader();
            const payload = {
                name: user.payload.name,
                phone: user.phone || "",
                email: user.email || "",
                city: user.payload.city || "",
                company: user.payload.company || "",
                search_type: user.payload.relation === "Work" ? 1 : 2,
                company_year: user.payload.year || "",
                school: user.payload.institution || "",
                school_year: user.payload.year || "",
                user_id: user.user_id,
            };
            const response = await API.post('/user/usercreate-for-rating', payload);
            window.location.href = '/review_form/' + base64Encode(response.review_id);

        }else if (user.user_id > 0) {

            const data = user.payload;
            const dataArr = {
                name: data.name || "",
                phone: data.phone || "",
                email: data.email || "",
                city: data.city || "",
                search_type: data.relation === "Work" ? 1 : data.relation === "Study" ? 2 : null,
                company: data.company || "",
                company_year: data.year || "",
                school: data.institution || "",
                school_year: data.year || ""
            };

            
            const queryString = encodeURIComponent(JSON.stringify(dataArr));
            window.location.href = '/search_result/?query=' + queryString;
        } else if (user.user_id === 0) {
            startLoader();
            const payload = {
                name: user.payload.name,
                phone: user.phone || "",
                email: user.email || "",
                city: user.payload.city || "",
                company: user.payload.company || "",
                search_type: user.payload.relation === "Work" ? 1 : 2,
                company_year: user.payload.year || "",
                school: user.payload.institution || "",
                school_year: user.payload.year || ""
            };
            const response = await API.post('/user/usercreate-for-rating', payload);
            window.location.href = '/review_form/' + base64Encode(response.review_id);
        } else if (user.user_id === -1) {
            handleSearchAgain();
        }
    };

    const handleSearchAgain = () => {
        resetChatForm(0);
        handleSearch();
    };

    const resetChat = () => {
        resetChatForm(1);
    };

    const resetChatForm = (type) => {
        if (type === 1) {
            setChatOpen(false);
        }
        setMessages([]);
        setUserInput("");
        setCurrentStep(0);
        setUserResponses({});
        setIsLastQuestion(false);
        setUserName("");
        setQuestions([
            { text: "Hi! Who are you looking for?", type: "input", key: "name", placeholder: "Type Name" },
            { text: "Got it! Do you have their phone number?", type: "options", options: ["No","Yes"], key: "hasPhone" },
        ]);
        setIsScrolled(false);
    };

    useEffect(() => {
        if (chatOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
        //console.log("messages", messages);
    }, [chatOpen]);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (chatBoxRef.current) {
    //             setIsScrolled(chatBoxRef.current.scrollTop > 0);
    //         }
    //     };
    //     if (chatBoxRef.current) {
    //         chatBoxRef.current.addEventListener("scroll", handleScroll);
    //     }
    //     return () => {
    //         if (chatBoxRef.current) {
    //             chatBoxRef.current.removeEventListener("scroll", handleScroll);
    //         }
    //     };
    // }, [chatBoxRef.current]);
    useEffect(() => {
        if (chat !== "1") return;
    
        let retries = 20;
        let timeoutId;
    
        const tryScroll = () => {
            if (chatBoxRef.current) {
                console.log("✅ Found chatBoxRef, scrolling to top...");
                chatBoxRef.current.scrollTo({
                    top: 0,
                    behavior: "smooth", // This already animates slowly
                });
            } else if (retries > 0) {
                retries--;
                console.log("⏳ chatBoxRef still null, retrying...");
                timeoutId = setTimeout(tryScroll, 600); // slower retry loop (600ms instead of 250ms)
            } else {
                console.log("❌ Gave up: chatBoxRef never became available.");
            }
        };
    
        // Add slight delay before starting to scroll
        timeoutId = setTimeout(tryScroll, 500); // wait half a second before trying
    
        return () => clearTimeout(timeoutId);
    }, [chat]);
    
    
     // run whenever `chat` changes
    


    
    

    // Tab key navigation logic (simplified, assuming it's unchanged)
    const handleTabKey = (e, msg, index) => {
        if (e.key === "Tab") {
            e.preventDefault();
            const isShift = e.shiftKey;
            const lastIndex = messages.length - 1;

            if (index === lastIndex && msg.showInput) {
                if (document.activeElement === inputRef.current) {
                    sendButtonRef.current?.focus();
                } else if (document.activeElement === sendButtonRef.current) {
                    if (msg.type === "options" && optionRefs.current[0]) {
                        optionRefs.current[0].focus();
                    } else if (backButtonRef.current) {
                        backButtonRef.current.focus();
                    } else {
                        inputRef.current?.focus();
                    }
                } else if (document.activeElement === backButtonRef.current) {
                    if (msg.type === "options" && optionRefs.current[0]) {
                        optionRefs.current[0].focus();
                    } else {
                        inputRef.current?.focus();
                    }
                } else if (msg.type === "options" && optionRefs.current.includes(document.activeElement)) {
                    const currentOptionIndex = optionRefs.current.indexOf(document.activeElement);
                    if (!isShift && currentOptionIndex < optionRefs.current.length - 1) {
                        optionRefs.current[currentOptionIndex + 1].focus();
                    } else if (isShift && currentOptionIndex > 0) {
                        optionRefs.current[currentOptionIndex - 1].focus();
                    } else if (!isShift && currentOptionIndex === optionRefs.current.length - 1) {
                        backButtonRef.current?.focus() || inputRef.current?.focus();
                    } else if (isShift && currentOptionIndex === 0) {
                        sendButtonRef.current?.focus();
                    }
                }
            }
        }
    };
    const handleInputChange = (e,msg) => {
        const value = e.target.value;
        //console.log("msg", msg);
        if (msg.key === "phone") {
            // Only allow numbers
            const numericValue = value.replace(/[^0-9]/g, '');
            if (numericValue.length <= 40) {
                setUserInput(numericValue);
            }
        } else {
            setUserInput(value);
        }
    };
    return (
        <>
            <Col lg={7} md={12} xs={12} className="flex justify-center">
                {!chatOpen && (
                    <Buttons
                        ariaLabel="button"
                        href="#"
                        className="!rounded-[21px] w-[80%] mx-[10px] font-medium font-serif uppercase lg:mb-[15px] landscape:lg:mb-[15px] !border-none !hover:border-none flex justify-center transition-all duration-700"
                        themeColor={["#556fff", "#ff798e"]}
                        size="lg"
                        color="#fff"
                        iconPosition="before"
                        title="Search for review..."
                        onClick={handleSearch}
                        icon={
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
                                <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z" />
                            </svg>
                        }
                    />
                )}
            </Col>
            {chatOpen && <div className="chat-overlay"></div>}
            <div ref={chatRef} className={`chat-container relative shadow-lg bg-white !p-0 ${chatOpen ? "b-d" : ""}`}>
                {chatOpen && (
                    <div>
                        <div className={`chat-header !m-0 !p-[13px] ${isScrolled ? "sc-s" : ""}`}>
                            <div>Start your search</div>
                            <button className="close-button" onClick={resetChat}>×</button>
                        </div>
                        <div className="chat-popup">
                            <div className="chat-box overflow-auto" ref={chatBoxRef}>
                                {messages.map((msg, index) => (
                                    <div key={index} className="message-wrapper">
                                        <div className="message-container">
                                            <article className={`${msg.sender === "bot" ? "article-div" : "article-div isLeft"}`}>
                                                {msg.sender === "bot" ? (
                                                    <div className="msg-grid-left">
                                                        <figure className="image">
                                                            <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/678226/channels/0ALU81JK7HUHVBYZFINKA0FOZXZNXF4E.png" />
                                                        </figure>
                                                        <span className="MessageDate"><b className="MessageDate__text">{msg.time}</b></span>
                                                    </div>
                                                ) : null}
                                                <div className={`${msg.sender === "bot" ? "msg-grid-center" : "msg-grid-center user-message fade-in left-in"}`}>
                                                    <div className={msg.sender === "bot" ? "MessageBubble bot-bubble" : "user-bubble"}>
                                                        <div className="MessageBubble__content">
                                                            <div className="MessageBubbleText">
                                                                {
                                                                    msg.founddata ? (
                                                                    <p>I found a match! <div style={{display:'flex'}}><img alt="" style={{width:'45px',height:'45px'}} className="rounded-full object-cover border" src={DOMAIN_URL+msg.founddata.profile_picture} />
                                                                    <div style={{display:'flex',alignSelf:'center',marginLeft:'10px',flexDirection:'column',lineHeight:'20px'}}><span style={{fontWeight:'600'}}>{msg.founddata.name}</span><span>{msg.founddata.city_name}</span></div></div> Would you like to proceed?</p>
                                                                ): msg.search_status && msg.search_status == 1?( 
                                                                    <p style={{display:'flex'}}>Searching… <img alt="" style={{width:'25px',height:'25px'}} className="rounded-full object-cover border" src={DOMAIN_URL+'/uploads/search_load.gif'} /></p>
                                                                ):(<p>{msg.text}</p>) }
                                                                
                                                            </div>
                                                            {msg.showInput && msg.sender === "bot" && msg.type === "input" && (
                                                                <div className="InputText is-animate-messages-on">
                                                                    <div className="field has-addons is-marginless">
                                                                        <div className="has-icons-right">
                                                                            <input
                                                                                className="input"
                                                                                ref={inputRef}
                                                                                placeholder={msg.placeholder?msg.placeholder+'...':'Type here...'}
                                                                                type="text"
                                                                                value={userInput}
                                                                                //onChange={handleInputChange}
                                                                                onChange={(e) => handleInputChange(e,msg)}
                                                                                onKeyDown={(e) => {

                                                                                    if (e.key === "Enter") {
                                                                                        if (msg.key === "phone" && !phoneRegex.test(userInput)) return;
                                                                                        if ((msg.key === "email" || msg.key === "hasEmailAfterPhone") && !emailRegex.test(userInput)) return;
                                                                                        handleResponse(userInput);
                                                                                    }

                                                                                    //if (e.key === "Enter") handleResponse(userInput);
                                                                                    handleTabKey(e, msg, index);
                                                                                }}
                                                                                tabIndex={0}
                                                                            />
                                                                            <button
                                                                                ref={sendButtonRef}
                                                                                type="button"
                                                                                className="input-icon-send-button"
                                                                                onClick={() => {
                                                                                    if (msg.key === "phone" && !phoneRegex.test(userInput)) return;
                                                                                    if ((msg.key === "email" || msg.key === "hasEmailAfterPhone") && !emailRegex.test(userInput)) return;
                                                                                    handleResponse(userInput);
                                                                                }}
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                                                                    <path fill="#ffc000" d="M1.1 21.757l22.7-9.73L1.1 2.3l.012 7.912 13.623 1.816-13.623 1.817-.01 7.912z"></path>
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {msg.key === "phone" && userInput && !phoneRegex.test(userInput) && (
                                                                        <p className="enterText" style={{ color: "red" }}>
                                                                            Please enter a valid phone number (numbers only, max 40 digits)
                                                                        </p>
                                                                    )}
                                                                    {(msg.key === "email" || msg.key === "hasEmailAfterPhone") && userInput && !emailRegex.test(userInput) && (
                                                                        <p className="enterText" style={{ color: "red" }}>
                                                                            Please enter a valid email address
                                                                        </p>
                                                                    )}
                                                                    {/* <p className="enterText">Press enter to send</p> */}
                                                                    {msg.is_skipbutton && (
                                                                        <span className="enterText" style={{cursor:'pointer'}} onClick={()=> handleResponse('Skipped')} >
                                                                            Skip
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {msg.type === "year" && msg.showInput && msg.sender === "bot" && (
                                                                <div className="InputText is-animate-messages-on">
                                                                    <div className="field has-addons is-marginless">
                                                                        <div className="has-icons-right">
                                                                            <input
                                                                                ref={inputRef}
                                                                                className="input"
                                                                                placeholder="Enter year (YYYY)"
                                                                                type="text"
                                                                                value={userInput}
                                                                                maxLength="4"
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value.replace(/\D/g, "");
                                                                                    if (value.length <= 4) setUserInput(value);
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === "Enter" && userInput.length === 4 && parseInt(userInput) <= new Date().getFullYear()) {
                                                                                        handleResponse(userInput);
                                                                                    }
                                                                                    handleTabKey(e, msg, index);
                                                                                }}
                                                                                tabIndex={0}
                                                                            />
                                                                            <button
                                                                                ref={sendButtonRef}
                                                                                type="button"
                                                                                className="input-icon-send-button"
                                                                                onClick={() => userInput.length === 4 && parseInt(userInput) <= new Date().getFullYear() && handleResponse(userInput)}
                                                                                disabled={userInput.length !== 4}
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                                                                    <path fill="#ffc000" d="M1.1 21.757l22.7-9.73L1.1 2.3l.012 7.912 13.623 1.816-13.623 1.817-.01 7.912z"></path>
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {userInput.length !== 4 && (
                                                                        <p className="enterText" style={{ color: "red" }}>
                                                                            Please enter exactly 4 digits.
                                                                        </p>
                                                                    )}
                                                                    {userInput.length === 4 && parseInt(userInput) > new Date().getFullYear() && (
                                                                        <p className="enterText" style={{ color: "red" }}>
                                                                            Year cannot be greater than {new Date().getFullYear()}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {index === messages.length - 1 && msg.type === "options" && msg.options && msg.showInput && (
                                                        <div className="options-wrapper">
                                                            <p className="choose-text">CHOOSE AN OPTION</p>
                                                            <div className="options-container" style={{justifyContent:"flex-start"}}>
                                                                {msg.options.map((option, i) => (
                                                                    <button
                                                                        key={i}
                                                                        ref={el => optionRefs.current[i] = el}
                                                                        className={`btn-explore-yellow margin_right30 ${option === "No" ? "chat_bot_yes" : ""}`}
                                                                        onClick={() => handleResponse(option)}
                                                                    >
                                                                        {option}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {index === messages.length - 1 && msg.type === "staticOptions" && msg.options && msg.showInput && (
                                                        <div className="options-wrapper">
                                                            <p className="choose-text">CHOOSE AN OPTION</p>
                                                            <div className="options-container" style={{ justifyContent: "flex-start" }}>
                                                                {msg.options.map((option, i) => (
                                                                    <button
                                                                        key={i}
                                                                        className={`btn-explore-yellow margin_right30 ${option.text === "Search Again" ? "chat_bot_yes" : ""}`}
                                                                        onClick={() => handleContinue(option.user)}
                                                                    >
                                                                        {option.text === "Yes" ? "Continue" : option.text}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {msg.sender === "user" ? (
                                                    <div className="msg-grid-right">
                                                        <span className="MessageDate userMessagedate"><b className="sc-fufdwm dqzGHg MessageDate__text">{msg.time}</b></span>
                                                    </div>
                                                ) : null}
                                            </article>
                                            {index === messages.length - 1 && msg.showInput && msg.sender === "bot" && (
                                                <div className="back-button-container" onClick={handleBack}>
                                                    <article className="back-grid-row">
                                                        <div className="back-grid-left"></div>
                                                        <div className="sc-lnsxGb kQehpR msg-grid-center" style={{ paddingLeft: "9px" }}>
                                                            <div className="sc-hGNhLO iuYYuj BackButton">
                                                                <button
                                                                    ref={backButtonRef}
                                                                    type="button"
                                                                    className="sc-blHHSb eLwlKU BackButton__Button custom-button button"
                                                                    aria-label="Back"
                                                                >
                                                                    <span className="BackButton__Icon">
                                                                        <span className="icon">
                                                                            <svg height="20" viewBox="0 0 24 24" className="sc-gQaihK iuxxgz">
                                                                                <path fill="var(--form_buttons_color)" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </span>
                                                                    <span className="BackButton__Label is-unselectable">Back</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Chatbot;